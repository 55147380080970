<template>
  <v-container>
    <v-speed-dial v-show="showBtn()" right bottom direction="top" transition="slide-y-reverse-transition" style=" position: absolute; position: fixed;" >
        <template v-slot:activator>
          <v-btn color="#0C2D48" fab @click="openDialog()"><v-icon> mdi-plus </v-icon></v-btn>
        </template>
    </v-speed-dial>
    <v-timeline>
      <v-timeline-item v-for="(item,i) in this.versionData" :key="i" icon="mdi-post" color="#0C2D48">
        <template v-slot:opposite>
          <h2><span style="color:red">{{changeDateFormat(item.created_at)}}</span></h2>
        </template>
        <v-card class="elevation-2">
          <v-card-title class="titleColor">Version {{item.version}}
          <v-spacer></v-spacer>
        <v-icon v-show="showBtn()" color="green" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon v-show="showBtn()" color="red" @click="softDelete(item)">mdi-trash-can</v-icon>
        </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div v-for="(line, index) in item.detail.split('\n')" :key="index">
              <v-icon>mdi-circle-small</v-icon>{{ line }}
            </div>
            <v-divider></v-divider>
            <div v-if="item.attachment">
              <h3 style="color:red"><b>ATTACHMENT</b></h3>
              <v-card v-for="(fileName, index) in item.attachment.split(',')" :key="`${index}-${fileName}`" flat class="pa-0">
                <v-icon color="error">mdi-image</v-icon>
                <span style="cursor: pointer;" class="pl-1" @click="openImage(fileName)">{{ fileName.replace(/-\d+/, '') }}</span>
              </v-card>
          </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>



    <v-dialog v-model="dialog" width="500" persistent>
      <v-row no-gutters> 
        <v-col>
          <v-card>
            <v-card-title class="titleColor">{{statusAction}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field v-model="obj.version" label="Version Number" dense outlined></v-text-field>
              <v-text-field v-if="statusAction == 'ADD VERSION'"  v-model="dateNow" label="Date" type="date" dense outlined></v-text-field>
              <v-text-field v-else v-model="obj.created_at" label="Date" type="date" dense outlined></v-text-field>
              <v-textarea  v-model="obj.detail" label="Details" dense outlined></v-textarea>
              <v-file-input hide-details small-chips v-model="files" label="Image" dense multiple :prepend-icon="null" outlined accept="image/png" persistent-placeholder autocomplete="off" @change="getFile()">
                <v-icon medium slot="append" color="#0C2D48">mdi-image-plus</v-icon>
              </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="statusAction == 'ADD VERSION'"  @click="insert()" color="primary">save</v-btn>
              <v-btn v-else @click="update()" color="green">Update</v-btn>
              <v-btn @click="closeDialog()" color="error">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from 'axios'
import swal from 'sweetalert2'
import moment from "moment"
export default {
  data() {
    return {
      obj:{},
      dialog:false,
      disable:false,
      files:[],
      newFiles:[],
      dateNow: moment().format("YYYY-MM-DD"),
      statusAction: ''
    }//end return
  },//end data
  computed:{
    ...mapState(['versionData']),
  },//end computed
  methods:{
    ...mapActions(['getVersion']),
    showBtn(){
      let user = this.loggedInUser;
      if(user.user_id == '31869') {
        return true;
      } else {
        return false;
      }
    },
    changeDateFormat (val){
      return moment(val).format("YYYY-MM-DD");
    },

    openDialog(){
      this.statusAction = "ADD VERSION"
      this.dialog =true;
    },

    closeDialog(){
      this.obj = {}
      this.dialog =false;
    },

    openImage(file){
      // console.log(file);
      this.getFileUrVersion(file)
    },

    insert(){
      let attachments = this.newFiles.map(item=>item.name).join(',')
      if (attachments){
        this.obj.attachment = attachments
      }
      this.obj.created_at = this.dateNow
      axios({
        method: 'post',
        url: `${window.api}/mysql/insertVersion`,
        data: this.obj,
        headers: {
            'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(()=>{
        this.dialog =false;
        swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Save",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getVersion()
      })
    },

    getFile() {
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let newName = file.name.replace(/\.[^/.]+$/, `-${this.$store.state.loggedInUser.user_id}$&`);
        let newFile = new File([file], newName, { type: file.type });
        this.newFiles.push(newFile);
      }
      this.uploadPicture(this.newFiles)
    },
    uploadPicture(file){
      this.uploadVesrionToAWS(file)
    },
    edit(item){
      this.obj = { ...item };
      // console.log(this.obj.attachment);
      this.obj.created_at = moment(this.obj.created_at).format("YYYY-MM-DD"),
      this.statusAction = `Edit Version ${this.obj.version}`
      this.dialog = true;
    },
    update(){
      let attachments = this.newFiles.map(item=>item.name).join(',')
      if (attachments){
        this.obj.attachment = attachments
      }
      axios({
        method: 'post',
        url: `${window.api}/mysql/updateVersion`,
        data: this.obj,
        headers: {
            'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(()=>{
        this.dialog =false;
        swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getVersion()
      })
    },
    softDelete(item){
      this.obj = { ...item };
      axios({
        method: 'post',
        url: `${window.api}/mysql/deleteVersion`,
        data: this.obj,
        headers: {
            'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(()=>{
        swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getVersion()
      })
    }
  },//end methods
  created(){
    this.getVersion()
  }
}
</script>

<style scoped>
.titleColor{
  background-color: #0C2D48;
  color: white;
}
</style>
